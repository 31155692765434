import React, { useState } from "react";
import "../style/login.css";
import { Container, Row, Col, Form, FormGroup, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import loginImg from "../assets/images/logocow.png";
import userIcon from "../assets/images/648ea891e68a91687070865.png";
import fb from "../assets/images/icons/facebook.svg";
import google from "../assets/images/icons/google.svg";
import linkedin from "../assets/images/icons/linkedin.svg";
import metamask from "../assets/images/icons/metamask.svg";
import PasswordStrengthMeter from "../shared/PasswordStrengthMeter";
import { useAuthStore } from "../context/authStore";
import { useTranslation } from "react-i18next";

const Register = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  //translation
  const { t } = useTranslation();

  //auth register
  const { signup, error, isLoading } = useAuthStore();

  //handle register button click
  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      await signup(email, password, fullName, confirmPassword);
      navigate("/verify-email");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div class="account">
      <div class="account-inner">
        <div class="account-left">
          <a href="/" class="account-left__logo">
            <img src={loginImg} />
          </a>
          <div class="account-left__content">
            <h5 class="account-left__subtitle">Welcome To Coinofworld</h5>
            <h3 class="account-left__title">{t("register.title")}</h3>
          </div>
          <div class="account-left__thumb">
            <img src={userIcon} />
          </div>
        </div>

        <div class="account-right-wrapper">
          <div class="account-right">
            <div class="account-content">
              <div class="account-form">
                <h3 class="account-form__title mb-0">{t("register.title")}</h3>
                <p class="account-form__desc">
                  Securely connect to your account
                </p>

                <div class=" d-flex gap-3 flex-wrap">
                  <div class="continue-google flex-fill">
                    <a href="#" class="btn w-100">
                      <span class="google-icon">
                        <img src={google} />
                      </span>
                    </a>
                  </div>
                  <div class="continue-google flex-fill">
                    <a href="#" class="btn w-100">
                      <span class="google-icon">
                        <img src={fb} />
                      </span>
                    </a>
                  </div>
                  <div class="continue-google flex-fill">
                    <a href="#" class="btn w-100">
                      <span class="google-icon">
                        <img src={linkedin} />
                      </span>
                    </a>
                  </div>
                </div>
                <div class="continue-google">
                  <button
                    type="button"
                    class="btn w-100 d-flex justify-content-center align-items-center"
                    id="metamask"
                  >
                    <span class="google-icon">
                      <img src={metamask} />
                      Continue with Metamask
                    </span>
                  </button>
                </div>
                <div class="other-option">
                  <span class="other-option__text">OR</span>
                </div>
                <form
                  onSubmit={handleRegister}
                >
                  <div class="row">
                    <div class="form-group">
                      <label class="form--label">{t("register.fullname")}</label>
                      <input
                        type="text"
                        name="fullName"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        class="form--control"
                        placeholder={t("register.fullname")}
                      />
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="form--label">
                        {t("register.email")}
                        </label>
                        <input
                          type="email"
                          class="form--control checkUser"
                          placeholder={t("register.email")}
                          name="email"
                          id='fullName' 
                          value={fullName} 
                          onChange={(e) => setFullName(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form--label">{t("register.password")}</label>
                        <input
                          type="password"
                          class="form--control @if (gs('secure_password')) secure-password @endif"
                          name="password"
                          id='password' 
                          value={password} 
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder={t("register.password")}
                          required
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form--label">
                        {t("register.confirm_password")}
                        </label>
                        <input
                          type="password"
                          class="form--control"
                          name="password_confirmation"
                          id='confirmPassword' 
                          value={confirmPassword} 
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder={t("register.confirm_password")}
                          required
                        />
                      </div>
                    </div>
                    <PasswordStrengthMeter password={password} />
                  </div>
                  <button
                    type="submit"
                    id="recaptcha"
                    class="btn btn--base w-100"
                  >
                   {t("register.title")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// return<section>
//     <Container>
//       <Row>
//         <Col lg='8' className='m-auto'>
//           <div className="login__container d-flex justify-content-between">
//             <div className="login__img">
//               <img src={regImg} alt="login" />
//             </div>

//             <div className="login__form">
//               <div className="user">
//                 <img src={userIcon} alt="n" />
//               </div>
//               <h2>{t("register.title")}</h2>
//               <Form onSubmit={handleRegister}>

//               <FormGroup>
//                   <input type="text" placeholder={t("register.fullname")} required id='fullName' value={fullName} onChange={(e) => setFullName(e.target.value)} />
//                 </FormGroup>

//                 <FormGroup>
//                   <input type="email" placeholder={t("register.email")} required id='email' value={email} onChange={(e) => setEmail(e.target.value)} />
//                 </FormGroup>

//                 <FormGroup>
//                   <input type="password" placeholder={t("register.password")} required id='password' value={password} onChange={(e) => setPassword(e.target.value)} />
//                 </FormGroup>

//                 <FormGroup>
//                   <input type="password" placeholder={t("register.confirm_password")} required id='confirmPassword' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
//                 </FormGroup>

//                 <PasswordStrengthMeter password={password} />

//                 <Button className='btn secondary__btn auth__btn' type='submit'>{t("register.login")}</Button>
//               </Form>
//               <p>{t("register.account")}  <Link to='/login'>{t("register.login")}</Link></p>
//             </div>
//           </div>

//         </Col>
//       </Row>
//     </Container>
//   </section>
export default Register;
