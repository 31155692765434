// import React, { useMemo, useState, useEffect } from "react";
// import "./Table.css";
// import {
//   useReactTable,
//   getCoreRowModel,
//   flexRender,
//   getSortedRowModel,
//   getFilteredRowModel,
// } from "@tanstack/react-table";
// import useCurrencyFetch from "../../hooks/useCurrencyFetch";
// import Calendar from "../../shared/Calendar";
// import { formatDate, formatNumber } from "../../utils/formatDate";
// import { useTranslation } from "react-i18next";

// const Table = ({ onAveragePriceChange }) => {
//   const { t } = useTranslation();
//   const [sorting, setSorting] = useState([]);
//   const [selectedDate, setSelectedDate] = useState(new Date());

//   // Search matching rows
//   const [searchTerm, setSearchTerm] = useState("");
//   //const highlightedRowRef = useRef(null);

//   const [filters] = useState({
//     code: "",
//     numToBasic: "",
//     value: "",
//   });
//   const [visibleColumns] = useState({
//     code: true,
//     numToBasic: true,
//     value: true,
//   });

//   const { data: mData, loading } = useCurrencyFetch(formatDate(selectedDate));

//   const cowValue =
//     mData.cowvalue !== null ? formatNumber(mData.cowvalue) : null;

//   const currency = useMemo(() => mData.currency || [], [mData.currency]);

//   const filteredData = useMemo(() => {
//     return currency.filter((item) => {
//       const matchesCode =
//         filters.code === "" ||
//         item.name.toLowerCase().includes(filters.code.toLowerCase());
//       const matchesnumToBasic =
//         filters.numToBasic === "" ||
//         item.numToBasic
//           .toLowerCase()
//           .includes(filters.numToBasic.toLowerCase());
//       const matchesValue =
//         filters.value === "" ||
//         item.value.toLowerCase().includes(filters.value.toLowerCase());
//       return matchesCode && matchesnumToBasic && matchesValue;
//     });
//   }, [currency, filters]);

//   useEffect(() => {
//     if (onAveragePriceChange) {
//       onAveragePriceChange(cowValue);
//     }
//   }, [cowValue, onAveragePriceChange]);

//   const columns = useMemo(
//     () =>
//       [
//         {
//           header: t("table.code"),
//           accessorKey: "code",
//           footer: t("table.code"),
//         },
//         {
//           header: t("table.numToBasic"),
//           accessorKey: "numToBasic",
//           footer: t("table.numToBasic"),
//         },
//         {
//           header: t("table.vnd"),
//           accessorKey: "value",
//           footer: t("table.vnd"),
//           cell: ({ row }) => {
//             const newValue = formatNumber(row.original.value);
//             const previousValue = formatNumber(row.original.valuePrevious);
//             const color = newValue > previousValue ? "value-up" : "value-down";
//             return <span className={color}>{newValue}</span>;
//           },
//         },
//       ].filter((column) => visibleColumns[column.accessorKey]),
//     [visibleColumns, t]
//   );

//   // React Table hook setup
//   const table = useReactTable({
//     data: filteredData,
//     columns,
//     getCoreRowModel: getCoreRowModel(),
//     getSortedRowModel: getSortedRowModel(),
//     getFilteredRowModel: getFilteredRowModel(),
//     state: {
//       sorting,
//     },
//     onSortingChange: setSorting,
//   });

//   // Clear filtering function
//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//   };

//   // useEffect(() => {
//   //   if (highlightedRowRef.current) {
//   //     highlightedRowRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
//   //   }
//   // }, [searchTerm]);
  
//   return (
//     <div className="data__table">
//       <h1> {t("table.title")}</h1>
//       <div className="cow__value">
//         <div className="col-6">
//           <Calendar onDateChange={setSelectedDate} />
//         </div>
//         <div className="col-5 row__price">
//           <div className="search-bar">
//             <input
//               type="text"
//               value={searchTerm}
//               onChange={handleSearchChange}
//               placeholder="Search..."
//             />
//             <div className="cow__price">1 COW = {cowValue} VND</div>
//           </div>
//         </div>
//       </div>
//       <div className="table-container">
//         <table>
//           <thead className="thead-dark">
//             {table.getHeaderGroups().map((headerGroup) => (
//               <tr key={headerGroup.id}>
//                 {headerGroup.headers.map((header) => (
//                   <th
//                     key={header.id}
//                     onClick={header.column.getToggleSortingHandler()}
//                     className="th-right"
//                   >
//                     {header.isPlaceholder
//                       ? null
//                       : flexRender(
//                           header.column.columnDef.header,
//                           header.getContext()
//                         )}
//                     {
//                       { asc: "🔼", desc: "🔽" }[
//                         header.column.getIsSorted() ?? null
//                       ]
//                     }
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//         </table>
//         <div className="table-body-container">
//           <table>
//             <tbody>
//               {loading ? (
//                 <tr>
//                   <td colSpan={columns.length}>
//                     <div className="load">
//                       <hr />
//                       <hr />
//                       <hr />
//                       <hr />
//                     </div>
//                   </td>
//                 </tr>
//               ) : (
//                 table.getRowModel().rows.map((row) => {
//                   const rowData = row.original;
//                   const isHighlighted =
//                     searchTerm &&
//                     Object.values(rowData).some((value) =>
//                       value
//                         .toString()
//                         .toLowerCase()
//                         .includes(searchTerm.toLowerCase())
//                     );
//                   return (
//                     <tr
//                       key={row.id}
//                       className={isHighlighted ? "highlight" : ""}
//                     >
//                       {row.getVisibleCells().map((cell) => (
//                         <td key={cell.id}>
//                           {flexRender(
//                             cell.column.columnDef.cell,
//                             cell.getContext()
//                           )}
//                         </td>
//                       ))}
//                     </tr>
//                   );
//                 })
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Table;

import React, { useMemo, useState, useEffect, useRef } from "react";
import "./Table.css";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import useCurrencyFetch from "../../hooks/useCurrencyFetch";
import Calendar from "../../shared/Calendar";
import { formatDate, formatNumber } from "../../utils/formatDate";
import { useTranslation } from "react-i18next";

const Table = ({ onAveragePriceChange }) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Search matching rows
  const [searchTerm, setSearchTerm] = useState("");
  const highlightedRowRef = useRef(null);

  const [filters] = useState({
    code: "",
    numToBasic: "",
    value: "",
  });
  const [visibleColumns] = useState({
    code: true,
    numToBasic: true,
    value: true,
  });

  const { data: mData, loading } = useCurrencyFetch(formatDate(selectedDate));

  const cowValue =
    mData.cowvalue !== null ? formatNumber(mData.cowvalue) : null;

  const currency = useMemo(() => mData.currency || [], [mData.currency]);

  const filteredData = useMemo(() => {
    return currency.filter((item) => {
      const matchesCode =
        filters.code === "" ||
        item.name.toLowerCase().includes(filters.code.toLowerCase());
      const matchesnumToBasic =
        filters.numToBasic === "" ||
        item.numToBasic
          .toLowerCase()
          .includes(filters.numToBasic.toLowerCase());
      const matchesValue =
        filters.value === "" ||
        item.value.toLowerCase().includes(filters.value.toLowerCase());
      return matchesCode && matchesnumToBasic && matchesValue;
    });
  }, [currency, filters]);

  useEffect(() => {
    if (onAveragePriceChange) {
      onAveragePriceChange(cowValue);
    }
  }, [cowValue, onAveragePriceChange]);

  const columns = useMemo(
    () =>
      [
        {
          header: t("table.code"),
          accessorKey: "code",
          footer: t("table.code"),
        },
        {
          header: t("table.numToBasic"),
          accessorKey: "numToBasic",
          footer: t("table.numToBasic"),
        },
        {
          header: t("table.vnd"),
          accessorKey: "value",
          footer: t("table.vnd"),
          cell: ({ row }) => {
            const newValue = formatNumber(row.original.value);
            const previousValue = formatNumber(row.original.valuePrevious);
            const color = newValue > previousValue ? "value-up" : "value-down";
            return <span className={color}>{newValue}</span>;
          },
        },
      ].filter((column) => visibleColumns[column.accessorKey]),
    [visibleColumns, t]
  );

  // React Table hook setup
  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  // Clear filtering function
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (highlightedRowRef.current) {
      highlightedRowRef.current.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }, [searchTerm]);

  return (
    <div className="data__table">
      <h1> {t("table.title")}</h1>
      <div className="cow__value">
        <div className="col-6">
          <Calendar onDateChange={setSelectedDate} />
        </div>
        <div className="col-5 row__price">
          <div className="search-bar">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search..."
            />
            <div className="cow__price">1 COW = {cowValue} VND</div>
          </div>
        </div>
      </div>
      <div className="table-container">
        <table>
          <thead className="thead-dark">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className="th-right"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    {
                      { asc: "🔼", desc: "🔽" }[
                        header.column.getIsSorted() ?? null
                      ]
                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </table>
        <div className="table-body-container">
          <table>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={columns.length}>
                    <div className="load">
                      <hr />
                      <hr />
                      <hr />
                      <hr />
                    </div>
                  </td>
                </tr>
              ) : (
                table.getRowModel().rows.map((row) => {
                  const rowData = row.original;
                  const isHighlighted =
                    searchTerm &&
                    Object.values(rowData).some((value) =>
                      value
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    );
                  return (
                    <tr
                      key={row.id}
                      className={isHighlighted ? "highlight" : ""}
                      ref={isHighlighted ? highlightedRowRef : null}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
