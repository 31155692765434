import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Router from "../../router/Router";
import "./Layout.css";
import { Container, Nav } from "reactstrap";

const Layout = () => {
  return (
    <div className="layout-container">
      <Header />
      <div className="contentz">
        <Router />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
