import React, { useState } from "react";
import "../style/login.css";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import loginImg from "../assets/images/logocow.png";
import userIcon from "../assets/images/648ea891e68a91687070865.png";
import fb from "../assets/images/icons/facebook.svg";
import google from "../assets/images/icons/google.svg";
import linkedin from "../assets/images/icons/linkedin.svg";
import metamask from "../assets/images/icons/metamask.svg";
import { useAuthStore } from "../context/authStore";
import { useTranslation } from "react-i18next";

// login
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, isLoading, error } = useAuthStore();

  //translation
  const { t } = useTranslation();

  //hanndel login button click
  const handleLogin = async (e) => {
    e.preventDefault();
    await login(email, password);
  };

  return (
    <div class="account">
      <div class="account-inner">
        <div class="account-left">
          <a href="{{ route('home') }}" class="account-left__logo">
            <img src={loginImg} />
          </a>
          <div class="account-left__content">
            <h5 class="account-left__subtitle">Welcome To Coinofworld</h5>
            <h3 class="account-left__title">LoginTo</h3>
          </div>
          <div class="account-left__thumb">
            <img src={userIcon} />
          </div>
        </div>

        <div class="account-right-wrapper">
          <div class="account-right">
            <div class="account-content">
              <div class="account-form">
                <h3 class="account-form__title mb-0">Log In</h3>
                <p class="account-form__desc">
                  Securely connect to your account
                </p>

                <div class=" d-flex gap-3 flex-wrap">
                  <div class="continue-google flex-fill">
                    <a
                      href="{{ route('user.social.login', 'google') }}"
                      class="btn w-100"
                    >
                      <span class="google-icon">
                        <img src={google} />
                      </span>
                    </a>
                  </div>
                  <div class="continue-google flex-fill">
                    <a
                      href="{{ route('user.social.login', 'facebook') }}"
                      class="btn w-100"
                    >
                      <span class="google-icon">
                        <img src={fb} />
                      </span>
                    </a>
                  </div>
                  <div class="continue-google flex-fill">
                    <a
                      href="{{ route('user.social.login', 'linkedin') }}"
                      class="btn w-100"
                    >
                      <span class="google-icon">
                        <img src={linkedin} />
                      </span>
                    </a>
                  </div>
                </div>
                <div class="continue-google">
                  <button
                    type="button"
                    class="btn w-100 d-flex justify-content-center align-items-center"
                    id="metamask"
                  >
                    <span class="google-icon">
                      <img src={metamask} />
                      Continue with Metamask
                    </span>
                  </button>
                </div>
                <div class="other-option">
                  <span class="other-option__text">OR</span>
                </div>
                <form onSubmit={handleLogin}>
                  <div class="form-group">
                    <label class="form--label">Username or Email</label>
                    <input
                      type="text"
                      name="username"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      class="form--control"
                      placeholder={t("login.email")}
                    />
                  </div>
                  <div class="form-group">
                    <div class="d-flex justify-content-between">
                      <label class="form--label">Password</label>
                      <a href="/forgot-password" class="forget-password">
                        {t("login.forgot")}
                      </a>
                    </div>
                    <div class="position-relative">
                      <input
                        name="password"
                        type="password"
                        class="form--control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={t("login.password")}
                      />
                      <div
                        class="password-show-hide far fa-eye toggle-password fa-eye-slash"
                        id="#toogle-password"
                      ></div>
                    </div>
                  </div>
                  <p>
                    {" "}
                    {t("login.account")}{" "}
                    <Link to="/register"> {t("login.create")}</Link>
                  </p>
                  <button type="submit" class="btn btn--base w-100">
                    {t("login.button")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // return <section>
  //   <Container>
  //     <Row>
  //       <Col lg='8' className='m-auto'>
  //         <div className="login__container d-flex justify-content-between">
  //           <div className="login__img">
  //             <img src={loginImg} alt="login" />
  //           </div>

  //           <div className="login__form">
  //             <div className="user">
  //               <img src={userIcon} alt="n" />
  //             </div>
  //             <h2>{t("login.title")}</h2>
  //             <Form onSubmit={handleLogin}>
  //               <FormGroup>
  //                 <input type="email" placeholder={t("login.email")} required id='email' value={email} onChange={(e) => setEmail(e.target.value)} />
  //               </FormGroup>

  //               <FormGroup>
  //                 <input type="password" placeholder={t("login.password")} required id='password' value={password} onChange={(e) => setPassword(e.target.value)} />
  //               </FormGroup>

  //               <Link to='/forgot-password' >
  //                 {t("login.forgot")}
  //               </Link>
  //               <Button className='btn secondary__btn auth__btn' type='submit'> {t("login.button")}</Button>
  //             </Form>
  //             <p> {t("login.account")} <Link to='/register'> {t("login.create")}</Link></p>
  //           </div>
  //         </div>

  //       </Col>
  //     </Row>
  //   </Container>
  // </section>
};

export default Login;
